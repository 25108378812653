<template>
  <div class="footer-wrapper">
    <CookieBanner
      v-if="!isCookieBannerAccepted"
      @okClicked="handleCookieOkClicked"
      @learnMoreClicked="handleCookieLearnMoreClicked"
    />

    <v-footer color="#3d3d3d" padless class="footer" ref="appFooter">
      <div class="footer__copyright">
        <span class="footer__company">
          <a target="_blank" href="https://teoxoft.com/">
            <strong>TEOXOFT</strong>
          </a>
        </span>

        <v-btn text class="footer__partner" @click="handleBecomePartnerClick"
          >{{
            buttonLabel(
              "landingView.partnerButtons.become_partner"
            ).toUpperCase()
          }}
        </v-btn>
        <v-btn text class="footer__partner" @click="handlePostListingClick"
          >{{ buttonLabel("landingView.partnerButtons.post_ad").toUpperCase() }}
        </v-btn>
      </div>

      <div class="footer__nav">
        <v-btn
          v-for="link in LINKS"
          :key="link.tr"
          color="#8C8C8C"
          text
          plain
          rounded
          @click="handleLegalClicked(link)"
        >
          {{ $t(link.tr) }}
        </v-btn>
      </div>
    </v-footer>
  </div>
</template>

<script>
import CookieBanner from "@/components/CookieBanner";
import { LINKS, partnerPortalItems } from "@/components/Common/constants/index";
import {
  addUTMparamsToURL,
  addHTTPrefererToURL,
} from "@/components/Common/helpers/utils";

export default {
  name: "TheFooter",

  components: {
    CookieBanner,
  },
  props: {
    isCookieBannerAccepted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    LINKS,
    partnerPortalItems,
    footerSize: null,
  }),
  computed: {
    isFooterFixed() {
      return this.$route.name === "map";
    },
    footerText() {
      if (this.$route.name === "calc") {
        return this.$t("footer.calc_text");
      } else if (this.$route.name === "faq") {
        return this.$t("footer.faq_text");
      } else {
        return this.$t("footer.map_text");
      }
    },
    partnerPortalUrl() {
      return `${process.env.VUE_APP_PARTNER_PORTAL_URL}/${this.$i18n.locale}`;
    },
    buttonLabel() {
      return (key) => {
        const translation = this.$t(key);
        return this.$vuetify.breakpoint.smAndDown
          ? translation.mobileLabel
          : translation.fullScreenLabel;
      };
    },
  },

  methods: {
    handleCookieOkClicked() {
      this.$emit("updateCookieBannerAccepted");

      localStorage.setItem("isCookiesAccepted", "true");
    },

    handleCookieLearnMoreClicked() {
      this.handleLegalClicked({ tr: "cookie", btn: "Cookie" });
    },

    handleLegalClicked(link) {
      this.$emit("handleLegalClicked", link);
    },

    handlePostListingClick() {
      this.$emit("postListingClick");
    },
    handleBecomePartnerClick() {
      const url = new URL(this.partnerPortalUrl);
      addUTMparamsToURL(url);
      addHTTPrefererToURL(url);
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss">
.footer-wrapper {
  position: relative;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-elevated {
    padding-bottom: 30px;
  }

  &__copyright {
    flex: 1;
    color: #d7d7d7;
    white-space: nowrap;
    font-size: 0.875rem;
    padding: 6px 16px;

    @media (max-width: 768px) {
      text-align: center;
      white-space: initial;
      padding: 8px;
      font-size: 0.875rem;
      white-space: nowrap;
      overflow: hidden;

      a {
        margin-right: 10px !important;
      }

      .v-btn {
        padding: 7.5px !important;
        margin-right: 0px !important;
      }
    }

    a {
      color: #fecc00 !important;
      text-decoration: none;
      margin-right: 15px;
    }

    .v-btn {
      color: #fff !important;
      margin-right: 0px;
      margin-top: -3px;
      padding: 0px;
    }
  }

  &__nav {
    display: flex;
    overflow: auto;

    @media (max-width: 960px) {
      display: none;
    }

    .v-btn__content {
      opacity: 1 !important;
    }

    button {
      font-size: 12px !important;
      padding: 0 12px !important;
      letter-spacing: 1.25px !important;
      transition: color 0.25s ease;

      &:hover {
        color: #ccc !important;
      }
    }
  }

  &__contact-us {
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
}
</style>
