<template>
  <AuthorizationVue>
    <v-app v-scroll="handleScrollMain" v-resize="onResize">
      <AppBar
        @triggerShowDrawer="triggerShowDrawer"
        @handleLegalClicked="handleLegalClicked"
        :isShowDrawer="isShowDrawer"
      />

      <NavigationDrawer
        class="drawer"
        :isShowDrawer="isShowDrawer"
        @triggerShowDrawer="triggerShowDrawer"
        @handleLegalClicked="handleLegalClicked"
      />

      <v-main id="scroll-target">
        <router-view />
      </v-main>

      <TheFooter
        :isCookieBannerAccepted="isCookieBannerAccepted"
        @updateCookieBannerAccepted="updateCookieBannerAccepted"
        @handleLegalClicked="handleLegalClicked"
        @postListingClick="handlePostListingClick"
      />

      <div
        id="mahalle-popout-wrapper"
        style="z-index: 21; background-color: var(--color-text)"
      ></div>

      <v-dialog
        v-model="isDialogVisible"
        scrollable
        :max-width="dialogMaxWidth"
      >
        <v-card>
          <v-card-title>
            <v-tooltip bottom :disabled="$vuetify.breakpoint.mobile">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="handleClickOpenInNew"
                  v-show="isInNewWindowIconVisible"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("open_in_new_tab") }}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn icon color="#3d3d3d" @click="isDialogVisible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <LegalCookie v-if="currentDialog.variant === 'cookie'" />

          <LegalDisclaimer v-if="currentDialog.variant === 'disclaimer'" />

          <LegalPrivacy v-if="currentDialog.variant === 'privacy'" />

          <LegalTac v-if="currentDialog.variant === 'terms_of_service'" />

          <LegalPartnerTac
            v-if="currentDialog.variant === 'partner_terms_of_service'"
          />

          <ContactFeedbackForm
            ref="contactFeedbackForm"
            v-if="currentDialog.variant === 'contact_us'"
            @feedBackMessageRecieve="onFeedBackMessageReceive"
            @closeClicked="isDialogVisible = false"
          />
        </v-card>
      </v-dialog>

      <SnackBarGlobal ref="snackbar" />
    </v-app>
    <photo-viewer></photo-viewer>

    <AgreementDialog
      v-if="isUserAuthenticated"
      :isOpen="!isUserAgreedWithPolicyRules"
    />
    <PostListingDialog
      v-model="isPostListingDialogOpen"
      @postListingAsIndividual="postListingAsIndividualOpen"
    />
    <PostListingIndividualDialog v-model="isPostListingIndividualDialogOpen" />
    <PostListingCompanyDialog v-model="isPostListingCompanyDialogOpen" />
  </AuthorizationVue>
</template>

<script>
import { api } from "./api";
import { eventBus } from "@/main";
import update from "@/update";

import LegalCookie from "@/components/Legal/LegalCookie";
import LegalDisclaimer from "@/components/Legal/LegalDisclaimer";
import LegalPrivacy from "@/components/Legal/LegalPrivacy";
import LegalTac from "@/components/Legal/LegalTac";
import LegalPartnerTac from "@/components/Legal/LegalPartnerTac";
import ContactFeedbackForm from "@/components/ContactFeedbackForm";

import AuthorizationVue from "@/components/Common/AuthorizationVue.vue";

import AppBar from "@/components/AppBar";
import TheFooter from "@/components/TheFooter";
import NavigationDrawer from "@/components/NavigationDrawer";
import PhotoViewer from "@/components/Common/PhotoViewer";

import AgreementDialog from "@/components/AgreementDialog.vue";
import PostListingDialog from "@/components/PostListingDialog.vue";
import PostListingIndividualDialog from "@/components/PostListingIndividualDialog.vue";
import PostListingCompanyDialog from "@/components/PostListingCompanyDialog.vue";

export default {
  name: "App",

  components: {
    PostListingCompanyDialog,
    ContactFeedbackForm,
    LegalCookie,
    LegalDisclaimer,
    LegalPrivacy,
    LegalTac,
    LegalPartnerTac,
    PhotoViewer,
    TheFooter,
    AppBar,
    AuthorizationVue,
    NavigationDrawer,
    AgreementDialog,
    PostListingDialog,
    PostListingIndividualDialog,
  },
  mixins: [update],

  data: () => ({
    isShowDrawer: false,
    isTelegramButtonVisible: true,
    lastScrollTop: 0,
    isCookieBannerAccepted: false,
    footerSize: null,
    currentDialog: {
      title: null,
      variant: null,
    },
    isDialogVisible: false,
    isPostListingDialogOpen: false,
    isPostListingIndividualDialogOpen: false,
    isPostListingCompanyDialogOpen: false,
    googleAdsObserver: null,
    bodyObserver: null,
  }),

  created() {
    eventBus.$on("showSnackbar", ({ text, color = "info", timeout = 6000 }) => {
      this.$refs.snackbar.showSnackbar(text, color, timeout);
    });
    eventBus.$on(
      "showSnackbarWithAction",
      ({ text, actionText, actionHandler, color = "info", timeout = 6000 }) => {
        this.$refs.snackbar.showSnackbarWithAction(
          text,
          actionText,
          actionHandler,
          color,
          timeout
        );
      }
    );
    eventBus.$on("gTagEvent", (eventName) => {
      this.$gtag.event(eventName, {
        send_to: process.env.VUE_APP_G_TAG_ID,
      });
    });

    eventBus.$on("legalClick", this.handleLegalClicked);
    // eventBus.$on(
    //   "postListingAsIndividualClick",
    //   this.postListingAsIndividualOpen
    // );
    eventBus.$on("showFeedbackForm", () => {
      this.isDialogVisible = true;
      this.currentDialog.variant = "contact_us";
    });
  },
  async mounted() {
    if (this.updateExists) this.refreshApp();

    if (this.isUserAuthenticated) {
      await this.loadUserData();
    }

    this.isCookieBannerAccepted = !!JSON.parse(
      localStorage.getItem("isCookiesAccepted")
    );

    this.footerComponent = document.querySelector("footer");

    this.onResize();

    eventBus.$on("handleLegalClicked", this.handleLegalClicked);
    eventBus.$on("postListingClick", this.handlePostListingClick);
    eventBus.$on(
      "postListingAsIndividualClick",
      this.postListingAsIndividualOpen
    );
    eventBus.$on("postListingAsCompanyClick", this.postListingAsCompanyOpen);

    //add google ads observer to track ad visibility
    this.bodyObserver = new MutationObserver(() => {
      const googleAdsEl = document.querySelector(
        ".adsbygoogle.adsbygoogle-noablate"
      );
      if (googleAdsEl) {
        this.checkGoogleAds();

        this.googleAdsObserver = new MutationObserver(() =>
          this.checkGoogleAds()
        );
        this.googleAdsObserver.observe(googleAdsEl, {
          attributes: true,
          attributeFilter: ["style"],
        });
        this.bodyObserver.disconnect();
      }
    });
    this.bodyObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  },

  beforeMount() {
    if (this.googleAdsObserver) {
      this.googleAdsObserver.disconnect();
    }
    if (this.bodyObserver) {
      this.bodyObserver.disconnect();
    }
  },

  methods: {
    async loadUserData() {
      const response = await api.get("auth/user");

      if (response) {
        this.$store.dispatch("setUser", response.data);
        return response.data;
      }
    },
    postListingAsIndividualOpen() {
      this.isPostListingIndividualDialogOpen = true;
    },
    postListingAsCompanyOpen() {
      this.isPostListingCompanyDialogOpen = true;
    },
    triggerShowDrawer() {
      this.isShowDrawer = !this.isShowDrawer;
    },
    handleScrollMain() {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      this.isTelegramButtonVisible = st <= this.lastScrollTop;
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },

    updateCookieBannerAccepted() {
      this.isCookieBannerAccepted = true;
    },

    onResize() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    onFeedBackMessageReceive() {
      eventBus.$emit("showSnackbar", {
        text: this.$t("feedback_message"),
        color: "success",
      });
    },
    handleLegalClicked(link) {
      this.isDialogVisible = true;
      this.currentDialog.variant = link.tr;
    },
    handlePostListingClick() {
      this.isPostListingDialogOpen = true;
    },
    handleClickOpenInNew() {
      this.isDialogVisible = false;
      const link = `/${this.$i18n.locale}/${this.currentDialog.variant}`;
      window.open(link, "_blank");
    },
    checkGoogleAds() {
      const googleAdsEl = document.querySelector(
        ".adsbygoogle.adsbygoogle-noablate"
      );
      if (googleAdsEl.style.display === "block") {
        document.querySelector("body").classList.add("body-padding-0");
        document.querySelector("footer").classList.add("footer-elevated");
      }
    },
  },
  computed: {
    isUserAgreedWithPolicyRules() {
      return this.$store.getters.user?.profile?.personal_data_agreement;
    },
    isUserAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isInNewWindowIconVisible() {
      return this.currentDialog.variant !== "contact_us";
    },
    dialogMaxWidth() {
      return this.currentDialog.variant === "contact_us" ||
        this.currentDialog.variant === "FeedBackMessageRecieve"
        ? "600px"
        : "";
    },
  },
  watch: {
    isDialogVisible(val) {
      if (!val && this.currentDialog.variant === "contact_us") {
        this.$refs.contactFeedbackForm.clearFormData();
      }
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  overflow-y: auto;

  &.body-padding-0 {
    padding: 0 !important;
  }
}

#mahalle-popout-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.v-application--wrap {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

.v-navigation-drawer {
  width: 100% !important;

  & .v-list {
    height: 100%;
    display: flex;
    flex-direction: column;

    & .v-list-item {
      justify-content: center;
      font-size: 2.5em;
    }
  }
}
</style>
